import { useState, createContext, useEffect} from "react";

// Firebase
import { db } from "../firebase/firebase";
import { doc, onSnapshot, collection, limit, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const SelectedGoalContext = createContext();

export default function UserProvider( {children, id} ) {

  const [data, setGoalData] = useState({total: 0, goalid: ''});
  const [isLoaded, setIsLoaded] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (auth) {
      
      const fetchData = async () => {
        try {
          if (id) {
            
            // get id from url and get the goal from the goals collection
            const goalPath = doc(db, `goals/${id}`);
            const unsub3 = onSnapshot(goalPath, (doc) => {
              if (doc.exists) {
                const docData = doc.data();
                setGoalData(docData);  
                setIsLoaded(true);   
              } else {
                navigate('/404');
                console.error('No such document!');
              }
            }); 
            return unsub3;

          } else {
            // if there is no id, get the first goal from the user's usergoals collection
            const goalQueryOne = query(
              collection(db, `users/${auth.currentUser.uid}/usergoals/`),
              where("completed", "==", false),
              limit(1)
            );

            // const userGoalPath = collection(db, `users/${auth.currentUser.uid}/usergoals/`);
            // const goalQueryOne = query(userGoalPath, limit(1));

            const unsub = onSnapshot(goalQueryOne, (querySnapshot) => {
                const firstDoc = querySnapshot.docs[0];
                if (firstDoc) {
                  const docData = firstDoc.data();
                  const goalPath = doc(db, `goals/${docData.goalid}`);
                  const unsub2 = onSnapshot(goalPath, (doc) => {
                    if (doc.exists) {
                      const docData = doc.data();
                      setGoalData(docData);
                      setIsLoaded(true);
                    }
                  });

                  return unsub2;
                } else {
                  setGoalData('Empty State');
                  setIsLoaded(true);
                }
              });
            return unsub;
          }
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };
      fetchData();
    }
    
    }, [auth, id]);

 

  return <SelectedGoalContext.Provider value={{data}}>{isLoaded && children}</SelectedGoalContext.Provider>;
}

