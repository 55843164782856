import React, {useContext} from 'react';
import styles from './headermain.module.css';

import { UserContext } from "../../../context/user"
import { useNavigate, NavLink, Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";


import Logo from '../../1atoms/Logo/Logo';
import ImageUser from '../../1atoms/ImageUser/ImageUser';
import HeaderDrawer from '../../2molecules/HeaderDrawer/HeaderDrawer';
import Button from '../../2molecules/Button/Button';
import Icons from '../../1atoms/Icons/Icons';
import NavMain from '../NavMain/NavMain';


export default function HeaderMain({type}) {

  
  const user1 = useContext(UserContext);

  // Sign Out trying to get this into a custom hook
  const auth = getAuth();
  const navigate = useNavigate();

  // Log Out
  function logout() {
    signOut(auth).then(() => {
      navigate('/signin');
    }).catch((error) => {
      console.log(error);
    });
  }

  // Marketing Header
  if (type === 'marketing') {
    return (
      <header className={styles.back}>
        <div className={styles.container}>
          <div className={`${styles.navContainer} ${styles.hideNavContainer}`}>
            <Link to="#tracking">Tracking</Link>
            <Link to="#fundraising">Fundraising</Link>
          </div>
          <div className={`${styles.navMobileContainer}`}>
            <Button type='popover' click='appLeft'>
              <Icons icon="bars" size="small"/>
            </Button>
            <HeaderDrawer type='popover' id='appLeft' position='left'>
              <Link to="#tracking">Tracking</Link>
              <Link to="#fundraising">Fundraising</Link>
            </HeaderDrawer>
          </div>
          <NavLink to="/"><Logo/></NavLink>
          <div className={`${styles.navContainer} ${styles.hideNavContainer}`}>
            <NavLink to="/signup">Sign Up</NavLink>
            <NavLink to="/signin"><Button type="primary">Sign In</Button></NavLink> 
          </div>
          <div className={`${styles.navMobileContainer}`}>
            <Button type='popover' click='appRight'>
              <Icons icon="right-to-bracket" size="small"/> 
            </Button>
            <HeaderDrawer type='popover' id='appRight' position='right'>
              <NavLink to="/signup">Sign Up</NavLink>
              <NavLink to="/signin">Sign In</NavLink> 
            </HeaderDrawer>
          </div>
        </div>
      </header>
  )
// Marketing Footer
  } else if (type === 'footer') {

    return (
      <footer className={`${styles.back} ${styles.backFooter}`}>
        <div className={styles.container}>
            <NavLink to="/"><Logo/></NavLink>
            <div className={styles.navContainer}>
              <NavLink to="/signup">Sign Up</NavLink>
              <NavLink to="/signin"><Button type="primary">Sign In</Button></NavLink> 
            </div>
        </div>   
        <p className={styles.center}>Created by: <a href="https://joevessell.com/" target="_blank">Joe Vessell</a></p> 
      </footer>
    )

  } else {
  // Application Header
  return (
      <>
      <header className={styles.back}>

        <div className={styles.container}>
          <div className={`${styles.navContainer} ${styles.hideNavContainer500}`}>
            <NavLink to="/main"><Logo/></NavLink>
            <NavLink to="/main">Home</NavLink>
            {/* {user1.data.type==='admin' && <NavLink to="/admin/users">Admin</NavLink>} */}
            
          </div>

          <div className={`${styles.userContainer} ${styles.hideNavContainer500}`}>
            <p>{user1.data.first}</p>
            <ImageUser color={user1.data.color} type="popover" size="tiny" image={user1.data.img} click='appRight'/>
          </div>

          {/* Mobile Left Nav */}
          <div className={`${styles.navMobileContainer500}`}>
            <Button type='popover' click='appLeft'>
              <Icons icon="bars" size="small"/>
            </Button>
            <HeaderDrawer type='popover' id='appLeft' position='left'>
              <NavMain popover={true}/>
              {/* Needs a conditional statement between profile settings and admin menus */}
            </HeaderDrawer>
          </div>
          <div className={`${styles.navMobileContainer500}`}>
            <NavLink to="/main"><Logo/></NavLink>
          </div>
          <div className={`${styles.navMobileContainer500}`}>
            <ImageUser color={user1.data.color} type="popover" size="tiny" image={user1.data.img} click='appRight'/>
          </div>

        </div>

      </header> 


       
      <HeaderDrawer type='popover' id='appRight' position='right'>
        <NavLink to="/profile"><span>Profile</span></NavLink>
        <NavLink to="/bar"><span>Progress Bar</span></NavLink>
        <NavLink to="/completedgoals"><span>Completed Goals</span></NavLink>
        {user1.data.type==='admin' && <NavLink to="/admin/users">Admin</NavLink>}
        <Button click={() => logout()}>Sign Out</Button>
      </HeaderDrawer>

      
 
      </>
  )
  }
}
